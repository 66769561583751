import { useEffect, useState } from 'react';
import { withComponentSetup } from 'src/components/Auth/_lib/ComponentSetup';
import { Drawer } from 'src/components/Common/@electron/Drawer';
import Link from 'src/components/Common/Link';
import { Hamburger } from 'src/components/Common/Svgs';
import track from 'src/lib/Analytics';
import { FIDPayloadType } from './examplePayload';

type MainPropsType = ReturnType<typeof resolver>;
interface LinkType {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    'Link URL': JSS.LinkField;
    'Link Text Override': {
      value: string;
    };
    LinkIcon?: { value: { src: string; alt: string; height: string; width: string } };
  };
}

interface LinksListType {
  linksList: LinkType[];
  sendAnalytics?: (href: string, linkText: string) => void;
}

interface DrawerLinksType extends LinksListType {
  toggleDrawer: () => void;
}

const resolver = (props: FIDPayloadType) => {
  return {
    logo: {
      src: props.fields.SiteLogo.value.src,
      altText: props.fields.SiteLogo.value.alt,
      href: props.fields.LogoImageUrl.value.href,
    },
    headerTitle: props.fields?.HeaderTitle?.value,
    linksList: props.fields.NavigationLinks,
    showMobileTitle: props.fields?.ShowMobileTitle?.value,
  };
};

const NavbarLinks = ({ linksList, sendAnalytics }: LinksListType) => (
  <ul className="hidden lg:flex lg:gap-24 xl:gap-32">
    {linksList.map((link, index) => {
      const linkValue = link.fields?.['Link URL']?.value;
      const iconData = link.fields?.LinkIcon?.value;
      return (
        <Link
          key={index}
          value={linkValue}
          className="btn btn-xs btn-flat btn-no-underline whitespace-nowrap text-teal-darker"
          onClick={() => sendAnalytics?.(linkValue?.href || '', linkValue?.text || '')}
        >
          {iconData?.src && (
            <img
              src={iconData.src}
              height={iconData?.height}
              width={iconData?.width}
              alt={iconData?.alt}
            ></img>
          )}
          <span>{linkValue.text}</span>
        </Link>
      );
    })}
  </ul>
);

const DrawerLinks = ({ linksList, toggleDrawer, sendAnalytics }: DrawerLinksType) => (
  <ul className="w-full flex flex-col py-16">
    {linksList.map((link, index) => {
      const linkValue = link.fields?.['Link URL']?.value;
      return (
        <div
          key={index}
          className="flex justify-center py-2 border-b border-b-gray hover:bg-teal-lighter"
        >
          <button className="flex flex-1 text-lg font-bold px-16 py-8" onClick={toggleDrawer}>
            <Link
              value={linkValue}
              className="btn btn-xs btn-flat whitespace-nowrap text-teal-darker h-28"
              onClick={() => sendAnalytics?.(linkValue?.href || '', linkValue?.text || '')}
            />
          </button>
        </div>
      );
    })}
  </ul>
);

const Main = (props: MainPropsType) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const showHamburger = props?.linksList?.length > 0;
  const showMobileTitle = props?.showMobileTitle || false;

  // FID home page load analytics
  useEffect(() => {
    const isFID = props.linksList?.[0]?.url?.includes('/jssfid/');
    if (isFID && window?.location?.pathname === '/') {
      track({
        event: 'send-VPV',
        'vpv-name': '/vpv/fid/page/find-it-duke/load/home-page',
      });
    }
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const sendAnalytics = (href: string, text: string, type: 'primary' | 'hamburger') => {
    track.component({
      category: `${type}-navigation`,
      action: href || 'na',
      label: text || 'na',
      event: 'event-click',
    });
  };

  return (
    <header
      data-testid="FID-header"
      className="js-site-header relative border-b border-gray isolate z-header"
      role="banner"
    >
      <div className="grid content-stretch px-16 md:px-24 h-56 md:h-64 xl:h-80 2xl:h-96 bg-white">
        <div className="relative w-full h-full container-5xl">
          <div className="container-full flex items-center h-full justify-between">
            <div className="flex gap-14 md:gap-16 xl:gap-20 2xl:gap-24 z-10">
              {showHamburger && (
                <div className="flex items-center">
                  <div>
                    <button
                      onClick={toggleDrawer}
                      className={`btn btn-tertiary btn-icon-only btn-xs lg:btn-sm -m-10 lg:-m-12 lg:hidden
                        ${drawerOpen} ? overflow-hidden touch-none : ''`}
                      type="button"
                      id="hamburger-trigger"
                      aria-controls="hamburger-drawer"
                      aria-expanded={drawerOpen}
                    >
                      <Hamburger fill="currentColor" />
                      <span>Menu</span>
                    </button>
                    <Drawer
                      variant="left"
                      isOpen={drawerOpen}
                      onClose={() => setDrawerOpen(false)}
                      name={'Navigation menu'}
                      logo={props.logo}
                      header={showMobileTitle ? props?.headerTitle : ''}
                    >
                      <DrawerLinks
                        linksList={props.linksList}
                        toggleDrawer={toggleDrawer}
                        sendAnalytics={(href, text) => sendAnalytics(href, text, 'hamburger')}
                      />
                    </Drawer>
                  </div>
                </div>
              )}

              <a
                className="flex items-center text-teal-darker focus-visible:outline-offset-8 focus-visible:rounded-sm"
                href={props.logo.href}
                aria-label="Find It Duke Homepage"
              >
                <img src={props.logo.src} alt={props.logo.altText} className="h-24 md:h-48" />
              </a>
              {props?.headerTitle && (
                <div
                  className={`${
                    showMobileTitle ? '' : 'hidden'
                  } sm:flex items-center pl-12 lg:pl-16 max-w-3xs text-lg xl:text-xl text-blue border-l border-gray`}
                >
                  <span className="mt-4 line-clamp-1">{props.headerTitle}</span>
                </div>
              )}
            </div>
            <NavbarLinks
              linksList={props.linksList}
              sendAnalytics={(href, text) => sendAnalytics(href, text, 'primary')}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

const MicrositeHeader = (props: any) => {
  return <Main {...props} />;
};

export default withComponentSetup({
  resolver,
  Component: MicrositeHeader,
});
